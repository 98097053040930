import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import { registerGuard } from './Guard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true, // Let this route require MSAL authentication (using an MSAL redirect)
    },
  },
  {
    path: '/quotations',
    name: 'Quotations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "quotations" */ '../views/Quotations.vue'),
    meta: {
      requiresAuth: true, // Let this route require MSAL authentication (using an MSAL redirect)
    },
  },
  {
    path: '/italian_csv',
    name: 'Italian Csv generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "quotations" */ '../views/ItalianCsv.vue'),
    meta: {
      requiresAuth: true, // Let this route require MSAL authentication (using an MSAL redirect)
    },
  },
  {
    path: '/wavin_tools_admin',
    name: 'WavinTools 4 Revit admin panel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "quotations" */ '../views/WavinTools4RAdmin.vue'),
    meta: {
      requiresAuth: true, // Let this route require MSAL authentication (using an MSAL redirect)
    },
  },
  {
    path: '/failed',
    name: 'Failed',
    component: () => import(/* webpackChunkName: "failed" */ '../views/Failed.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerGuard(router);

export default router;
