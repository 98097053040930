/* eslint-disable max-len */

import { RouteLocationNormalized, Router } from 'vue-router';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionType, PopupRequest, RedirectRequest,
} from '@azure/msal-browser';
import { msalInstance, loginRequest } from '../authConfig';
import api from '@/api';
import {
  getDecodedAccessJwt,
  setDecodedAccessJwt,
  setDecodedIdJwt,
  getDecodedIdJwt,
} from '../authzUtils';

const accounts = msalInstance.getAllAccounts();

const accessTokenRequest = {
  scopes: [process.env.VUE_APP_MSAL_DIRECTORDERING_API_SCOPE as string],
  account: accounts[0],
  redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI as string,
};

const setAuthorizationHeader = (authResult: AuthenticationResult) => {
  api.setHeaders({
    Authorization: `Bearer ${authResult.accessToken}`,
  });
};

const getAuthResult = async () => {
  let authResult;
  try {
    authResult = await msalInstance.acquireTokenSilent(accessTokenRequest);
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      try {
        authResult = await msalInstance.acquireTokenPopup(accessTokenRequest);
      } catch (error) {
        console.log(error);
      }
      console.log(e);
    }
  }

  if (authResult) {
    setAuthorizationHeader(authResult);
  }
  return authResult;
};

const parseJwt = (token: any) => {
  // https://stackoverflow.com/a/38552302/6643814
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

const authorizeGeneral = (authResult: AuthenticationResult | undefined) => {
  try {
    const accessToken = authResult?.accessToken;
    setDecodedAccessJwt(parseJwt(accessToken));
    const idToken = authResult?.idToken;
    setDecodedIdJwt(parseJwt(idToken));

    return getDecodedAccessJwt().roles?.length > 0
            || getDecodedIdJwt().roles?.length > 0;
  } catch {
    return false;
  }
};

export async function isAuthenticated(interactionType: InteractionType, loginReq?: PopupRequest|RedirectRequest): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return msalInstance.handleRedirectPromise().then(() => {
    if (accounts.length > 0) {
      return getAuthResult().then((authResult) => authorizeGeneral(authResult)).catch(() => false);
    }

    // User is not signed in and attempting to access protected route. Sign them in.
    if (interactionType === InteractionType.Popup) {
      return msalInstance.loginPopup(loginReq).then((authResult) => authorizeGeneral(authResult)).catch(() => false);
    } if (interactionType === InteractionType.Redirect) {
      return msalInstance.loginRedirect(loginReq).then(() => true).catch(() => false);
    } if (interactionType === InteractionType.Silent) {
      return msalInstance.acquireTokenSilent(accessTokenRequest).then((authResult) => authorizeGeneral(authResult)).catch(() => false);
    }

    return false;
  }).catch(() => false);
}

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.meta.requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(InteractionType.Redirect, request);
      if (!shouldProceed) {
        // eslint-disable-next-line no-restricted-globals
        location.assign('https://account.microsoft.com/account');
        return false;
      }
      return shouldProceed;
    }

    return true;
  });
}
