let headers = {
  'Content-Type': 'application/json',
};

const setHeaders = (newHeaders: any) => {
  headers = {
    ...headers,
    ...newHeaders,
  };
};

function processFetch<T>(fetchPromise: Promise<Response>): Promise<T> {
  return fetchPromise
    .then((response) => {
      if (response.ok) {
        return response.json() as Promise<T>;
      }

      return response.text().then((value) => {
        const info = {
          status: response.status,
          statusText: response.statusText,
          message: value,
        };
        throw new Error(JSON.stringify(info, null, 2));
      });
    })
    .catch((err) => Promise.reject(err));
}

function get<T>(path: string): Promise<T> {
  return processFetch<T>(fetch(path, { headers }));
}

function post<T>(path: string, data: any): Promise<T> {
  return processFetch<T>(fetch(path, { method: 'POST', headers, body: JSON.stringify(data) }));
}

function put<T>(path: string, data: any): Promise<T> {
  return processFetch<T>(fetch(path, { method: 'PUT', headers, body: JSON.stringify(data) }));
}

function del<T>(path: string, data?: any): Promise<T> {
  return processFetch<T>(fetch(path, {
    method: 'DELETE',
    headers,
    body: data ? JSON.stringify(data) : undefined,
  }));
}

export default {
  get,
  post,
  put,
  delete: del,
  setHeaders,
};
