let decodedAccessJwt: any = {};
let decodedIdJwt: any = {};

export const setDecodedAccessJwt = (jwt: any) => {
  decodedAccessJwt = jwt;
};

export const getDecodedAccessJwt = () => decodedAccessJwt;

export const setDecodedIdJwt = (jwt: any) => {
  decodedIdJwt = jwt;
};

export const getDecodedIdJwt = () => decodedIdJwt;

const roleExistInAccessToken = (roleName: string) : boolean => {
  try {
    return decodedAccessJwt.roles.some((el: string) => el === roleName);
  } catch {
    return false;
  }
};

const roleExistInIdToken = (roleName: string) : boolean => {
  try {
    return decodedIdJwt.roles.some((el: string) => el === roleName);
  } catch {
    return false;
  }
};

export const authzQuotations = () => roleExistInAccessToken('Quotations');

export const authzOrders = () => roleExistInAccessToken('Orders');

export const authzItalianCsv = () => roleExistInIdToken('ItalianCsv');

export const authzQuotsOrOrds = () => authzQuotations() || authzOrders();

export const authzWavinToolsAdmin = () => roleExistInIdToken('WavinToolsAdmin');
