import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './authConfig';
import App from './App.vue';
import router from './router';
import store from './store';

// Add Bootstrap CSS. IMPROVE: use bootstrap-vue instead
import 'bootstrap/dist/css/bootstrap.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';

const head = createHead();

createApp(App)
  .use(msalPlugin, msalInstance)
  .use(store)
  .use(router)
  .use(head)
  .mount('#app');
